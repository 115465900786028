import React from "react";

import Layout from "../../components/layout";
import SmallBanner from "../../components/smallBanner";

const Quality = () => {
  const lang = "pl";

  return (
    <Layout
      seoTitle="Strona główna"
      lang={lang}
      translationEN="/en/our-services/quality/"
      translationDE="/de/angebot/qualitat/"
    >
      <SmallBanner
        title="Jakość"
        aditionalBreadcrump="Oferta"
        aditionalBreadcrumpLink="/oferta/"
      />
      <section className="text-section quality-info text-section--last">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-6 col-xl-5 col-xxl-5">
              <h2 className="small color--main mb-60">
                System kontroli jakości PalettenWerk
              </h2>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-lg-3 col-xl-4">
              <img
                className="quality-info__left-image"
                src={require("../../assets/img/quality2.jpg")}
                alt="Jakość"
              />
            </div>
            <div className="col-12 col-lg-9 col-xl-8">
              <div className="text-section__content">
                <p className="mb-30">
                  W PalettenWerk obowiązuje 6-etapowy system kontroli jakości,
                  który stanowi gwarancję powtarzalności, wytrzymałości oraz
                  długotrwałej użyteczności produkowanych palet. System obejmuje
                  kontrolę dostaw elementów składowych palet, kontrolę procesu
                  produkcyjnego oraz produktów gotowych. Wszystkie procesy
                  kontrolne zintegrowane są w ramach systemu zarządzania
                  jakością.
                </p>
                <p className="mb-60">
                  Kontrola jakości rozpoczyna się już u dostawców elementów do
                  produkcji palet i służy eliminacji ewentualnych
                  nieprawidłowości mogących wystąpić na etapie przygotowywania
                  surowca do produkcji. Elementy składowe palet podlegają
                  kontroli również na etapie przyjmowania do zakładu i na każdym
                  stanowisku pracy, przez które przechodzą.
                </p>
                <img
                  src={require("../../assets/img/quality1.jpg")}
                  alt="Jakość"
                />
                <div className="content__qulity-check">
                  <div className="qulity-check__item">
                    <h4 className="item__title bold mb-50">
                      Kontrola procesu produkcyjnego
                    </h4>
                    <div className="item__content ">
                      <p className="content__item">
                        Kontrola el. składowych
                        <br />
                        palety u dostawców
                      </p>
                      <p className="content__item">
                        Kontrola na etapie
                        <br />
                        przyjęcia dostaw
                      </p>
                      <p className="content__item">
                        Kontrola wewnętrzna
                        <br />
                        na etapie produkcji
                      </p>
                    </div>
                  </div>
                </div>
                <div className="content__qulity-check">
                  <div className="qulity-check__item">
                    <h4 className="item__title bold mb-50">
                      Kontrola produktów gotowych
                    </h4>
                    <div className="item__content">
                      <p className="content__item">
                        Kontrola wewnętrzna
                        <br />
                        produktów gotowych
                      </p>
                      <p className="content__item">
                        Kontrola zewnętrzna
                        <br />
                        produktów gotowych
                      </p>
                      <p className="content__item">
                        Kontrola zewnętrzna
                        <br />
                        Europalet zgodności
                        <br />z normą UIC 435-2
                      </p>
                    </div>
                    <p className="item__bottom-info bold">
                      Certyfikat ISO 9001:2015
                    </p>
                  </div>
                </div>
                <div className="content__qulity-check content__qulity-check--ready">
                  <div className="qulity-check__item">
                    <h4 className="item__title bold mb-50">Produkty gotowe</h4>
                    <div className="item__content">
                      <p className="content__item">Certyfikat EPAL</p>
                      <img
                        className="content__item"
                        src={require("../../assets/img/palete-quality.png")}
                        alt="Paleta"
                      />
                      <p className="content__item">Certyfikat IPPC</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default Quality;
